import React, { Fragment }  from 'react'
import ImgMenina from '../images/LP/menino-kingdom.svg' 

import {
  column,
  columns,
  container,
  contatoLp,
  content,
  mobile,
  desktop,
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      <div css={[contatoLp, content]}>
        <div css={container}>
          <div css={columns}>
            <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <img css={mobile} src={ImgMenina} alt='' style={{ marginBottom: 32 }} />
              <h3>Dê uma <span>escola de presente</span> para o seu filho</h3>
              <h4>Preencha Agora e agende uma Visita à Kingdom.</h4>
              <div className='form-go'>
                <form
                  name='agendar-visita'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita'
                  />
                  <p>
                    <input type="text" name="name" placeholder="Nome" required/>
                  </p>
                  <p>
                    <input type="text" name="email" placeholder="Melhor e-mail" required/>
                  </p>
                  <p>
                    <input type="text" name="telefone" placeholder="Telefone" required/>
                  </p>
                  <button type="submit">Agendar uma visita</button>
                </form>
              </div>
            </div>
            <div css={column}>
              <img css={desktop} src={ImgMenina} alt='' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header
