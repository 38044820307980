import React, { Fragment }  from 'react'
import ImgTitle from '../images/LP/CVA.svg' 
import ImgMenina from '../images/LP/menina-kingdom.png' 
import ImgLine from '../images/LP/cta-kingdom.svg' 
import ImgLineM from '../images/LP/cta-kingdom-m.svg' 

import {
  column,
  columns,
  container,
  CVALp,
  content,
  mobile,
  desktop,
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      <div css={[CVALp, content]} id="nutricao">
        <div css={container}>
          <div css={columns}>
            <div css={column}>
              <h5 style={{ marginTop: 32 }}>Nutrição e psicomotricidade</h5>
              <img src={ImgTitle} alt='' style={{ marginBottom: 52 }} />
              <h4 style={{ marginBottom: 24 }}>É tão gostoso aprender. É tão divertido quando tudo o que a gente precisa para crescer está ao alcance das mãos.</h4>
              <h4>Quando aprender é tão natural que a gente se alimenta e alimenta a imaginação numa colherada só.</h4>
            </div>
            <div css={column}>
              <img src={ImgMenina} alt='' style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
      <div css={desktop}><img src={ImgLine} alt='' style={{ width: '100%', marginTop: '-130px' }} /></div>
      <div css={mobile}><img src={ImgLineM} alt='' style={{ width: '100%', marginTop: '-130px' }} /></div>
    </Fragment>
  )
}

export default Header