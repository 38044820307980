import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import Header from '../components/HeaderLp'
import BaseSolida from '../components/BaseSolidaLp'
import ComverViverAprender from '../components/ComerViverAprenderLp'
import EnsinoPersonalizado from '../components/EnsinoPersonalizadoLp'
import Bilinguismo from '../components/BilinguismoLp'
import Contato from '../components/ContatoLp'
import LogoKK from '../images/LP/logo-fractal.svg' 
import Menu from '../components/MenuLp'

import { 
  container,
  columns,
  column,
  footerLp
} from '../components/styles/Base.styles'

const LpPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Imagine uma escola, Imagine Kingdom" />
    <Menu />
    <Header />
    <BaseSolida id="base-solida" />
    <ComverViverAprender />
    <EnsinoPersonalizado id="ensino-personalizado" />
    <Bilinguismo id="bilinguismo" />
    <Contato />
    <section css={footerLp}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <img src={LogoKK} alt='' />
            <p>@2024 FractalTecnologia. Alguns direitos reservados.</p>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
)

export default LpPage
