import React, { Fragment }  from 'react'
import ImgCastle from '../images/LP/base-solida.svg' 

import {
  column,
  columns,
  container,
  BasePersonalizada,
  content,
  desktop,
  mobile,
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      <div css={[BasePersonalizada, content]} id="base-solida">
        <div css={container}>
          <div css={columns}>
            <div css={column} style={{ display: 'flex', alignItems: 'end' }}>
              <img css={desktop} src={ImgCastle} alt='' title='' />
            </div>
            <div css={column}>
              <h5 style={{ marginTop: 32 }}>Base sólida em português e matemática</h5>
              <h3>Performance <span>acima da média</span> se constrói desde cedo</h3>
              <h4 style={{ marginBottom: 24 }}>Só uma escola autoral, composta por gente especial e especializada em educação seria capaz de criar materiais, desenvolver atividades, construir espaços e ambientes tão fluídos e fazer passear por eles uma experiência de ensino e aprendizagem tão rica quanto a que se pratica na Kingdom.</h4>
              <h4 style={{ marginBottom: 124 }}>Uma boa base de comunicação e linguagem, aliada a um trabalho consistente de raciocínio lógico é o essencial que o aluno Kingdom experimenta como diferencial em sua formação.</h4>
              <img css={mobile} src={ImgCastle} alt='' style={{ marginBottom: '-18px' }} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header