import React, { Fragment }  from 'react'
import ImgFlags from '../images/LP/flags.svg'

import {
  column,
  columns,
  container,
  BilinguismoLp,
  content,
  mobile,
  desktop,
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      <div css={[BilinguismoLp, content]} id="bilinguismo">
        <div css={container}>
          <div css={columns}>
            <div css={column}>
              <img css={mobile} src={ImgFlags} alt='' style={{ marginTop: '-220px' }} />
              <h5 style={{ marginTop: 32 }}>Bilinguismo</h5>
              <h3>Lidar com a <span>língua</span>, a <span>linguagem</span> e a <span>cultura</span></h3>
              <h4>O programa de bilinguismo da Kingdom é baseado no aprendizado a partir do contexto. É a língua assimilada através dos gestos, da interação com os ambientes, com o aprendizado da cultura e a convivência com o outro. É a boa e velha articulação entre a língua, a linguagem e a cultura.</h4>
            </div>
            <div css={column}>
              <img css={desktop} src={ImgFlags} alt='' style={{ marginTop: '-190px' }} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header