import React, { Fragment }  from 'react'
import ImgTitle from '../images/LP/CVA.svg' 
import ImgRocket from '../images/LP/rocket.svg' 

import {
  column,
  columns,
  container,
  EnsinoPersonalizadoLp,
  content,
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      <div css={[EnsinoPersonalizadoLp, content]} id="ensino-personalizado">
        <div css={container}>
          <div css={columns}>
            <div css={column}>
              <img src={ImgRocket} alt='' />
            </div>
            <div css={column}>
              <h5 style={{ marginTop: 32 }}>Ensino personalizado</h5>
              <h3>Abordagem <span>atualizada, personalizada e focada</span> no desenvolvimento completo da criança</h3>
              <h4 style={{ marginBottom: 24 }}>Imagine que é possível aprender de verdade, aprender com solidez. Que a <span>proximidade</span> do aluno e a <span>profundidade</span> no olhar em relação ao seu desenvolvimento garantem que aprender será sempre uma aventura instigante. Imagine que há um lugar que faz  nos sentirmos únicos e que lá, a gente tem a sensação de que vale a pena ser quem somos! Imagine Kingdom.</h4>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header