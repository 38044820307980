import React from 'react'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
// import {AccordionWrapper, AccordionItem} from 'custom-react-accordion'
// import 'custom-react-accordion/dist/Accordion.css'
import MenuIcon from '../images/new/icons/menu/menuItem.svg'
import Logo from '../images/new/logo-kingdom-white.svg'
import { Link } from 'gatsby'
import Facebook from '../images/new/icons/menu/facebook.svg'
import Instagram from '../images/new/icons/menu/instagram.svg'
import 'react-accessible-accordion/dist/fancy-example.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import {
  container,
  linkTo,
	linksAcordion
} from '../components/styles/Rodal.styles'
import { socialIcons } from './styles/Footer.style'

const customStyles = {
  background: '#5416FF',
	overflowX: 'auto'
}

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	show() {
		this.setState({ visible: true });
	}

	hide() {
		this.setState({ visible: false });
	}

	render() {
		return (
			<div>
				<img src={MenuIcon} onClick={this.show.bind(this)} />
				<Rodal customStyles={customStyles} visible={this.state.visible} onClose={this.hide.bind(this)} width='100%' height='100%'>
					<div css={container} style={{ margin: 'auto' }}>
						<div style={{ textAlign: 'left' }}><img src={Logo} /></div>
						<div css={linkTo} style={{ textAlign: 'left', marginTop: 150 }}>
							<Link to='/atividades-e-projetos'>Atividades e Projetos</Link>
              <Link to='/calendario'>Calendário Escolar</Link>
							<Link to='/blog'>Blog</Link>
							<Link to='/contatos'>Contato</Link>
							<Link to='/'>Serviço aos Pais</Link>
							<Link to='/'>Matrículas Abertas</Link>
							<div css={socialIcons} style={{ margin: 24, display: 'flex' }}>
								<a style={{ border: 'none' }} href='https://www.facebook.com/kingdomschooldf'><img src={Facebook} /></a>
								<a style={{ border: 'none' }} href='https://www.instagram.com/kingdomschooldf/'><img src={Instagram} /></a>
							</div>
						</div>
					</div>
				</Rodal>
			</div>
		)
	}
}

export default App
