import React, { Fragment }  from 'react'
import ImgTitle from '../images/LP/imagine-uma-escola.svg' 
import ImgMenina from '../images/LP/menina-pulando-header.png' 
import ImgLine from '../images/LP/line.svg'
import ImgLineM from '../images/LP/line-m.svg'
import LogoKK from '../images/LP/logo-kk.svg' 

import {
  column,
  columns,
  container,
  header2,
  content,
  mobile,
  desktop,
  logo
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      {/* <section css={logo}>
        <div css={container}>
            <div css={columns}>
              <div css={column}>
                <img src={LogoKK} alt='' style={{ width: 160 }} />
              </div>
            </div>
          </div>
      </section> */}
      <div css={[header2, content]} style={{ marginTop: 112 }}>
        <div css={container}>
          <div css={columns}>
            <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <img css={mobile} src={ImgMenina} alt='' style={{ marginBottom: 32 }} />
              <img src={ImgTitle} alt='' />
              <form
                name='agendar-visita'
                method='POST'
                action='/sucesso'
                data-netlify='true'
              >
                <input
                  type='hidden'
                  name='form-name'
                  value='agendar-visita'
                />
                <p>
                  <input type="text" name="name" placeholder="Nome" required/>
                </p>
                <p>
                  <input type="text" name="email" placeholder="Melhor e-mail" required/>
                </p>
                <p>
                  <input type="text" name="telefone" placeholder="Telefone" required/>
                </p>
                <button type="submit">Agendar uma visita</button>
              </form>
            </div>
            <div css={column}>
              <img css={desktop} src={ImgMenina} alt='' />
            </div>
          </div>
        </div>
      </div>
      <div css={desktop}><img src={ImgLine} alt='' style={{ width: '100%' }} /></div>
      <div css={mobile}><img src={ImgLineM} alt='' style={{ width: '100%' }} /></div>
    </Fragment>
  )
}

export default Header