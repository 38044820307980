import React, { Fragment, useState } from 'react'
import { Link } from 'gatsby'
import * as Scroll from 'react-scroll'
import ResponsiveMenu from '../components/ResponsiveNavbar'
import Open from '../images/open.svg'
import Close from '../images/close-y.svg'
import Modal from '../components/MenuIndexLp'
import Facebook from '../images/new/icons/menu/facebook.svg'
import Instagram from '../images/new/icons/menu/instagram.svg'
import LogoWhite from '../images/new/logo-kingdom-white.svg'
import LogoKK from '../images/LP/logo-kk.svg' 

import {
  navBar4,
  container,
  logo,
  navBarMenu,
  navMenu,
  mobileMenu,
  togle3,
  mobile,
  desktop,
  headerMobile
  // btnMobile
} from '../components/styles/NavBar.style'

import {
  socialIcons
} from '../components/styles/Rodal.styles'

const NavBar = props => {
  const [visible, setVisible] = useState(false)
  const handleMenuCliclk = e => {
    e.preventDefault();
  }
  return (
    <div css={navBar4}>
      <div css={container}>
        <div css={logo} title='KINGDOM School'>
          <Link
            to='/'
            offsetTop={100}
            onFocus={() => setVisible(false)}
            css={desktop}
          >
            <img src={LogoKK} alt='Logo' style={{ width: 220 }} />
          </Link>
          <Link
            to='/'
            offsetTop={100}
            onFocus={() => setVisible(false)}
            css={mobile}
          >
            <img src={LogoKK} alt='Logo' style={{ width: 220 }} />
          </Link>
        </div>
        <div css={navMenu}
          style={{
            display: props.isMenuDisabled ? 'none' : 'flex'
          }}>
          <ResponsiveMenu
            menuOpenButton={<img src={Open} alt='/' />}
            menuCloseButton={<img src={Close} alt='/' />}
            changeMenuOn='960px'
            largeMenuClassName={navBarMenu}
            smallMenuClassName={mobileMenu}
            menuVisible={visible}
            setMenu={setVisible}
            menu={
              <div css={togle3}>
                {props.location && !props.location.pathname.match(/^\/(#.*|\?.*)*$/)?
                  (
                    <Fragment>
                       <Link
                        to='#base-solida'
                      >
                        Base sólida
                      </Link>
                      <Link
                        to='kingdom-school'
                        style={{ marginLeft: 24 }}
                      >
                        Nutrição e psicomotricidade
                      </Link>
                      <Link
                        to='estrutura'
                        style={{ marginLeft: 24 }}
                      >
                        Ensino Personalizado
                      </Link>
                      <Link
                        to='calendario'
                        style={{ marginLeft: 24 }}
                      >
                        Bilinguismo
                      </Link>
                      <div style={{ marginLeft: 24 }}>
                        <Modal />
                      </div>
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <div css={desktop}>
                        <Link
                          to='#base-solida'
                        >
                          Base sólida
                        </Link>
                        <Link
                          to='#nutricao'
                          style={{ marginLeft: 48 }}
                        >
                          Nutrição e psicomotricidade
                        </Link>
                        <Link
                          to='#ensino-personalizado'
                          style={{ marginLeft: 48 }}
                        >
                          Ensino Personalizado
                        </Link>
                        <Link
                          to='#bilinguismo'
                          style={{ marginLeft: 48 }}
                        >
                          Bilinguismo
                        </Link>
                        {/* <div style={{ marginLeft: 48 }}>
                          <Modal />
                        </div> */}
                      </div>
                      <div css={mobile} style={{ flexDirection: 'column' }}>
                        <div css={headerMobile}>
                          <a
                            href='/'
                            css={mobile}
                            style={{ border: 'none' }}
                          >
                            <img src={LogoWhite} alt='Logo' style={{ maxWidth: '80%', marginRight: 60 }} />
                          </a>
                          <div>
                            <a 
                              href=''
                              style={{ border: 'none' }}
                            >
                              <img src={Close} />
                            </a>
                          </div>
                        </div>
                        <Link onClick={() => setVisible(false)} to='#base-solida' style={{ paddingLeft: 24 }}>Base sólida</Link>
                        <Link onClick={() => setVisible(false)} to='#nutricao' style={{ paddingLeft: 24, textAlign: 'left' }}>Nutrição e psicomotricidade</Link>
                        <Link onClick={() => setVisible(false)} to='#ensino-personalizado' style={{ paddingLeft: 24, textAlign: 'left' }}>Ensino Personalizado</Link>
                        <Link onClick={() => setVisible(false)} to='#bilinguismo' style={{ paddingLeft: 24 }}>Bilinguismo</Link>
                        <div css={socialIcons} style={{ margin: 24, display: 'flex' }}>
                          <a style={{ border: 'none', width: 'auto', marginRight: 16 }} href='https://www.facebook.com/kingdomschooldf'><img src={Facebook} /></a>
                          <a style={{ border: 'none' }} href='https://www.instagram.com/kingdomschooldf/'><img src={Instagram} /></a>
                        </div>
                      </div>
                    </Fragment>
                  )
                }
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
